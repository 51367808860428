@import "scss/index";
.icon {
  width: 16px;
  height: 16px;
  border: 2px solid $color-rocky;
  opacity: 0.32;

  //&::before {
  //  background-image: url('./images/checkMark.svg');
  //}

  &.active {
    background-color: $color-rocky;
    //background-image: url('./images/checkMark.svg');
    //background-position: 50% 3px;
    border: none;
    opacity: 1;
  }

  &.hover {
    opacity: 0.64;
    box-shadow: 0 0 4px $color-rocky;
  }
}
