@function safe($value) {
  @return var(--size-#{$value});
}

// Increases or decreases $color lightness by fixed $value in "Figma style"

@function figmaLighten($color, $value) {
  $newLightness: round(lightness($color) / 1%) + $value;
  @return change-color($color, $lightness: if($newLightness <= 100, $newLightness, 100));
}

@function figmaDarken($color, $value) {
  $newLightness: round(lightness($color) / 1%) - $value;
  @return change-color($color, $lightness: if($newLightness >= 0, $newLightness, 0));
}