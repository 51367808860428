@import "scss/index";
.icon {
  position: relative;
  flex: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 4px solid $color-rocky;
  opacity: 0.32;

  &.active {
    opacity: 1;

    &::before {
      top: 2px;
      left: 2px;
      width: 8px;
      height: 8px;
      background-color: #fff;
      box-shadow: 0 2px 2px rgba(19, 57, 134, 0.32);
    }
  }
}
