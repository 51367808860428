@import "scss/index";
.carousel {
  position: relative;

  .fullScreen {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all ease 0.25s;

    &:hover {
      opacity: 1;
    }
  }

  :global {

    .glider {
      position: relative;
      overflow-y: hidden;
      transform: translateZ(0);
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
      }

      &.draggable {
        cursor: grab;
        user-select: none;

        .glider-slide img {
          user-select: none;
          pointer-events: none;
        }
      }

      &.drag {
        cursor: grabbing;
      }
    }

    .glider-track {
      z-index: 1;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      transform: translateZ(0);
    }

    .glider-slide {
      align-content: center;
      justify-content: center;
      width: 100%;
      min-width: 50px;
      user-select: none;
    }

    .glider-prev,
    .glider-next {
      position: absolute;
      top: 0;
      z-index: 2;
      height: 100%;
      padding: 0;
      color: $color-white;
      font-size: 0;
      line-height: 0;
      text-decoration: none;
      background: none;
      border: 0;
      outline: none;
      cursor: pointer;
      opacity: 0.72;
      transition: color 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      user-select: none;

      &:hover {
        opacity: 1;
      }

      &.disabled {
        cursor: default;
        opacity: 0;
      }
    }

    .glider-prev {
      left: 0;
    }

    .glider-next {
      right: 0;
    }

    .glider-hide {
      opacity: 0;
    }

    .glider-dots {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      user-select: none;
    }

    .glider-dot {
      display: block;
      width: 4px !important;
      height: 4px !important;
      margin: 0 4px !important;
      padding: 0;
      background: $color-arrival;
      border: 0;
      border-radius: 50%;
      outline: none;
      cursor: pointer;

      &:hover,
      &:focus,
      &.active {
        background: $color-rocky;
      }
    }
  }
}

.text {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, 0, 0);
}

@include media-mobile {

  .carousel {

    :global {

      .glider-dot {
        width: safe(7);
        height: safe(7);
        margin: 16px 3px 5px 4px;
      }
    }
  }
}

@include media-desktop {

  .carousel {

    :global {

      .glider-dot {
        width: 8px;
        height: 8px;
        margin: 16px 4px;
      }
    }
  }
}
