@import "scss/index";
.video,
.preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preview {
  background-position: center;
  background-size: cover;
  outline: none;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #000;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-left-color: $color-white-70;
    content: '';

    margin-top: -15px;
    margin-left: -10px;
    border-top-width: 15px;
    border-bottom-width: 15px;
    border-left-width: 27px;
  }
}


@include media-mobile {

  .icon {
    width: safe(72);
    height: safe(72);
  }
}

@include media-desktop {

  .icon {
    width: 72px;
    height: 72px;
  }
}
