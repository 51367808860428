@import "scss/index";
.clickable:hover {
  cursor: pointer;
  
  .username40 {
    color: $color-interstellar;
  }

  .position40 {
    opacity: 1;
  }
}