@import "scss/index";
.root {
  // overflow: hidden; // TODO check do we need this
  background-size: contain;
  @include no-user-select;
}

.roundShape {
  border-radius: 100%;
}

.dark {
  background-color: #3d75e4;
}

.light {
  background-color: #E4EDFB;
}

.size-16 {
  width: 16px;
  height: 16px;
}

.size-24 {
  width: 24px;
  height: 24px;
}

.size-32 {
  width: 32px;
  height: 32px;
}

.size-40 {
  width: 40px;
  height: 40px;
}

.size-56 {
  width: 56px;
  height: 56px;
}

.size-72 {
  width: 72px;
  height: 72px;
}

.size-104 {
  width: 104px;
  height: 104px;
}

.hoverOpacity:hover {
  opacity: 1;
}

.fullScreenHover:hover {
  & svg {
    opacity: 0.72;
  }
}
