// Focus

@mixin focus-shadow {
  border-color: #0f77ff;
  box-shadow: 0 0 4px 2px rgba(15, 119, 255, 0.8);
}

@mixin mouse-focus {
  :global(.js-focus-visible) &:focus:not(:global(.focus-visible)) {
    @content;
  }
}

@mixin keyboard-focus {
  :global(.js-focus-visible) &:global(.focus-visible):focus {
    @content;
  }
}


// Padding underlay

@mixin padding-underlay($padding: 100%) {

  &::after {
    display: block;
    width: 100%;
    padding-bottom: $padding;
    content: '';
  }
}


// Placeholder

@mixin placeholder {
  &::placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}


// Disable user select

@mixin no-user-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


// Absolute Centring

@mixin absolute-centring($offset: 0) {
  position: absolute;
  top: $offset;
  right: $offset;
  bottom: $offset;
  left: $offset;
  margin: auto;
}

@mixin absolute-v-centring {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

// Figma uikit scrollbar

@mixin uikit-scrollbar {
  // firefox
  & {
    scrollbar-color: transparentize($color-titanic, 0.92) transparent;
    scrollbar-width: thin;
  }

  // the rest, besides IE
  &::-webkit-scrollbar-track
  {
    background-color: transparent;
  }

  &::-webkit-scrollbar
  {
    width: 4px;
    height: 25%;
  }

  &::-webkit-scrollbar-thumb
  {
    background-color: transparentize($color-titanic, 0.92);
    border-radius: 2px;
  }
}

// Hide Scrollbar

@mixin no-scrollbar {
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

// Color

@mixin button-colors($color, $property: background-color) {

  @media (hover: hover) {

    &:hover {
      #{$property}: figmaLighten($color, 3);
    }

    &:active {
      #{$property}: figmaDarken($color, 3);
    }

    @include keyboard-focus {
      #{$property}: figmaLighten($color, 3);
    }
  }
}