@import "scss/index";
.container {
  position: relative;
  background-color: #000;
  overflow: hidden;

  &::after {
    display: block;
    padding-bottom: 56.25%;
    content: '';
  }
}
