@import "scss/index";
@include media-mobile {

  .logo {
    height: 32px;
  }
}

@include media-except-mobile {

  .logo {
    height: 40px;
  }
}
