@import "scss/index";
.bg-rocky {
  background-color: $color-rocky;
}

.bg-gentlemen {
  background-color: $color-gentlemen;
}

.bg-snatch {
  background-color: $color-snatch;
}

.bg-matrix {
  background-color: $color-matrix;
}

.border-rocky {
  border: 2px solid $color-rocky;
}

.border-gentlemen {
  border: 2px solid $color-gentlemen;
}

.border-snatch {
  border: 2px solid $color-snatch;
}

.border-matrix {
  border: 2px solid $color-matrix;
}